import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import {publishReplay,refCount,catchError } from 'rxjs/operators'

import { NavigatorSitecoreTemplate } from '../navigator-sitecore-template';
import { Configuration } from '../../../app.constants';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import { MemberLobProviderLinksModel } from '../member-lob-provider-sitecore-template';
import { LanguageService } from './language.service';


@Injectable({
  providedIn: 'root'
})

export class NavigatorSitecoreService {

  constructor(private http: HttpClient, private configuration: Configuration, private languageService: LanguageService) {
  }

  public getNavigatorSitecoreContent(): Observable<NavigatorSitecoreTemplate> {
      return this.http.get<NavigatorSitecoreTemplate>(
        this.languageService.getContentServiceUrlWithParameter(this.configuration.NavigatorContentServiceParameter)
      )
        .pipe(
          publishReplay(1)
        ,refCount()
        ,catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
  }

}


