
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService, UrlHelperService } from 'angular-oauth2-oidc';
import { HeaderSitecoreTemplate } from '../header-sitecore-template';
import { Configuration } from '../../../app.constants';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import { Log } from '../log'


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class LoggingService {

    private loggingServiceUrl;
    constructor(private http: HttpClient, private configuration: Configuration, private authService: OAuthService) {
        this.loggingServiceUrl = configuration.LoggingServiceUrl;
    }

    logError(error: any) {
        var errorResponse;
        try {
            let log = this.createErrorLog(error);
            this.http.post<string>(this.loggingServiceUrl, JSON.stringify(log), httpOptions)
                .subscribe(res => { const response = res });
        }
        catch (e) {
            throw e;
        }
    }

    private createErrorLog(error: any) {
        var date = new Date();
        let log: Log = new Log();
        log.Date = new Date();
        log.Message = error.message;
        log.HostName = this.configuration.BaseMemberPortalUrl;
        log.Component = this.configuration.ApplicationName;
        log.Exception = String(error.stack);
        log.Level = this.configuration.ErrorLevel;
        log.Thread = "";
        log.UserName = this.getUserName();
        return log;
    }

    private getUserName(): string {
        if (this.authService.getIdentityClaims() != null ) {
            let claims = this.authService.getIdentityClaims();
            return claims["sub"];
        }
        else return '';

    }

}