import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PhoneProperties } from '../../phone-properties';
import { CommonService } from "../../../../core/shared/services/common.service";

@Component({
  selector: 'app-user-phone',
  templateUrl: './user-phone.component.html',
  styleUrls: ['./user-phone.component.scss'],
  providers: [],
})
export class UserPhoneComponent implements OnInit {

  private isValidPhone: boolean;

  constructor(private commonService: CommonService) { }

  @Input() phoneProperties = new PhoneProperties();
  @Output() emitPhone = new EventEmitter();

  ngOnInit() {
  }

  public onPhoneChange(phoneValue: string, isPhoneInValid: boolean) {
    this.phoneProperties.Value = phoneValue;
    if (this.commonService.isInvalidPhoneNumber(phoneValue) || isPhoneInValid || (this.phoneProperties.Value.length <= 0 && this.phoneProperties.IsRequired)) {
      this.isValidPhone = false;
      this.phoneProperties.IsValid = false;
    } else {
      this.isValidPhone = true;
      this.phoneProperties.IsValid = true;
    }

    this.emitPhone.emit({
      phoneValue: this.phoneProperties.Value,
      isValid: this.isValidPhone
    });
  }
}
