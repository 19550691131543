import {
    HttpClient,
    HttpParams,
    HttpHeaders,
    HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { SessionTimerSitecoreTemplate } from '../session-timer-sitecore-template';
import { Configuration } from '../../../app.constants';

import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import { Injectable, Inject } from '@angular/core';
import {publishReplay, refCount, catchError} from 'rxjs/operators';
import { LanguageService } from './language.service';

@Injectable()
export class SessionTimerSitecoreService {

    constructor(private http: HttpClient, private configuration: Configuration, private languageService: LanguageService) {
    }

    getSessionTimerSitecoreContent(): Observable<SessionTimerSitecoreTemplate> {
        var errorResponse;

        try {
            return this.http
                .get<SessionTimerSitecoreTemplate>(
                    this.languageService.getContentServiceUrlWithParameter(this.configuration.SesssionTimerContentServiceParameter)
                    )
                .pipe(
                publishReplay(1)
                , refCount()
                , catchError(error =>
                    HttpResponseHelper.handleError(this.configuration, error)
                ));
        } catch (e) {
            throw e;
        }
    }
}
