import { Address } from './address'

export class PrimaryCareProvider {
    constructor() {
        this.Address = new Address();
    }
    public NPI: string
    public ProviderId: string
    public ProviderName:string
    public Address: Address
    public PhoneNumber: string
}