import { Address } from '../../member-portal/shared/address';

export class Provider {
    constructor() {
        this.AddressInfo = new Address();
    }
    public EntityType: string
    public NPI: string
    public ProviderId: string
    public ProviderName: string
    public TypeCode: string
    public TypeDescription: string
    public SpecialityCode: string
    public SpecialityDescription: string
    public Gender: string
    public LOBCode: string
    public AddressInfo: Address;
    public PhoneNumber: string;
}