
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Configuration } from '../../../app.constants';


export class HttpResponseHelper {

    public static handleError(configuration: Configuration, error: HttpErrorResponse) {
        console.error(error);
        return observableThrowError(error || configuration.ServerError);
    }

    //public static extractData(configuration: Configuration, res: HttpResponse<Object>) {
    //    if (res.status < configuration.MinimumSuccessCode || res.status > configuration.MaximumSuccessCode) {
    //        console.log(res.status);
    //        throw new Error(configuration.BadResponseStatus + res.status);

    //    }
    //    return res || {};
    //}
}