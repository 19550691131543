import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { PCPInfoViewProperties } from '../../pcp-info-view-properties';
import { Provider } from '../../provider';
import { AddressViewProperties } from '../../../shared/address-view-properties';
import { AddressComponent } from '../../../shared/components/address/address.component';
import { Configuration } from '../../../../app.constants';
import { CommonService } from '../../services/common.service';
import { Address } from '../../../../member-portal/shared/address';

@Component({
    selector: 'app-pcpinfo',
    templateUrl: './pcp-info.component.html',
    styleUrls: ['./pcp-info.component.scss']
})

export class PCPInfoComponent implements OnInit, OnChanges {

    @Input() properties: PCPInfoViewProperties = new PCPInfoViewProperties();
    @Input() provider: Provider = new Provider();
    @Input() healthNetworkCode: string;
    providerAddress: AddressComponent = new AddressComponent(this.commonService);

    constructor(
        public configuration: Configuration,
        private commonService: CommonService
        ) { }

    ngOnInit() {
        this.setMailingAddressView();
        this.setMailingAddressData();
    }

    ngOnChanges(changes: any) {
        this.setMailingAddressView();
        this.setMailingAddressData();
    }

    setMailingAddressView() {
        this.providerAddress.properties = new AddressViewProperties();
        this.providerAddress.properties.ReadOnly = true;
    }

    setMailingAddressData() {
        this.providerAddress.address = new Address();
        this.providerAddress.address.Address1 = this.provider.AddressInfo.Address1;
        this.providerAddress.address.Address2 = this.provider.AddressInfo.Address2;
        this.providerAddress.address.City = this.provider.AddressInfo.City;
        this.providerAddress.address.State = this.provider.AddressInfo.State;
        this.providerAddress.address.ZipCode = this.provider.AddressInfo.ZipCode;
    }

}
