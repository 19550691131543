<div *ngIf="!viewProperties.ReadOnly" class="email-phone-fixed-box">
  <form #phoneForm="ngForm">
    <fieldset>
      <div class="email-phone-fixed-title" *ngIf="viewProperties.Title">
        <h4>{{ viewProperties.Title || "your phone number is" }}</h4>
      </div>
      <div
        class="form-group"
        [ngClass]="{
          'is-invalid':
            phone.hasError('required') ||
            phone.hasError('pattern') ||
            phone.hasError('maxlength') ||
            (phone.hasError('forbiddenPhone') && phone.touched && phone.dirty)
        }"
      >
        <div class="email-phone-fixed-title">
          <label for="phone" class="control-label">{{
            viewProperties.FieldLabel || "phone number"
          }}</label>
          <label
            for="phone"
            class="float-right"
            *ngIf="viewProperties.UpdateMemberProfileText"
          >
            <a
              [routerLink]="['/updatememberprofile']"
              class="control-label pull-right"
            >
              {{
                viewProperties.UpdateMemberProfileText || "edit Member Profile"
              }}
            </a></label
          >
        </div>

        <input
          id="phone"
          name="phone"
          placeholder="___ - ___ - ____"
          [(ngModel)]="phoneNumber"
          class="form-control"
          [ngClass]="{
            'is-invalid':
              phone.hasError('required') ||
              phone.hasError('pattern') ||
              phone.hasError('maxlength') ||
              (phone.hasError('forbiddenPhone') && phone.touched && phone.dirty)
          }"
          maxlength="12"
          #phone="ngModel"
          pattern="^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
          phone
          required
          [forbiddenNumbers]="forbiddenPhones"
          max="100"
          min="0"
        />

        <div *ngIf="phone.touched && phone.dirty" class="invalid-feedback">
          <label *ngIf="phone.hasError('required')" for="phone">
            {{
              validationMessages.RequiredMessage || "phone number is required."
            }}
          </label>
          <label *ngIf="phone.hasError('maxlength')" for="phone">
            {{
              validationMessages.MaxLengthMessage ||
                "phone number has exceeded the number of characters allowed."
            }}
          </label>
          <label
            *ngIf="
              phone.hasError('pattern') || phone.hasError('forbiddenPhone')
            "
            for="phone"
          >
            {{
              validationMessages.InvalidInputMessage ||
                "phone number is invalid."
            }}
          </label>
        </div>
      </div>
      <div *ngIf="viewProperties.ShowCheckbox" class="form-group">
        <div class="row">
          <div class="col-md-8">
            <span for="PrimaryPhoneSSA" class="inputswitch-switchlabel">
              {{
                viewProperties.CheckboxText ||
                  "Do we have your consent to forward to Orange County Social Services Agency?"
              }}
            </span>
          </div>
          <div class="col-md-4 text-right">
            <p-inputSwitch
              class=""
              id="PrimaryPhoneSSA"
              name="PrimaryPhoneSSA"
              [(ngModel)]="checkboxValue"
              (ngModelChange)="onCheckboxChange()"
            ></p-inputSwitch>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="viewProperties.ShowButtons">
        <div class="email-phone-fixed-footer">
          <div class="col-12 text-right">
            <button
              class="btn btn-link"
              (click)="resetView(); phoneForm.form.markAsPristine()"
            >
              {{ viewProperties.CancelButtonText || "cancel" }}
            </button>
            <button
              class="btn btn-primary"
              type="submit"
              (click)="updatePhone()"
              [disabled]="
                !phoneForm.form.valid || !phone.dirty || phoneNumber == oldValue
              "
            >
              {{ viewProperties.SubmitButtonText || "update" }}
            </button>
          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>

<div *ngIf="viewProperties.ReadOnly">
  <h4 class="email-phone-fixed-title">
    {{ viewProperties.Title || "your phone number is" }}
  </h4>
  <div class="contact-info-box">
    <label class="email-phone-fixed-title">{{
      viewProperties.FieldLabel || "phone number"
    }}</label>
    <div>{{ phoneNumber }}</div>
  </div>
</div>
