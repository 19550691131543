import { Directive, EventEmitter, HostListener, Output, ElementRef} from '@angular/core';
import { NgControl } from "@angular/forms";
@Directive({
    selector: '[ngModel][phone]'

})
export class PhoneMaskDirective {
    constructor(public model: NgControl, private el: ElementRef<HTMLElement> ) { }

    @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
    value: any;
    private regex: RegExp = new RegExp(/-?[0-9]$/g);
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-','Delete'];
  
    @HostListener('input', ['$event']) onInputChange($event, backspace) {
        // remove all mask characters (keep only numeric)
        this.value = $event.target.value.replace(/\D/g, '');
        // special handling of backspace necessary otherwise
        // deleting of non-numeric characters is not recognized
        // this laves room for improvement for example if you delete in the
        // middle of the string
        if (backspace) {
            this.value = this.value.replace (0, this.value.length - 1);
        }

        // don't show braces for empty value
        if (this.value.length == 0) {
            this.value = '';
        }
        // don't show braces for empty groups at the end
        else if (this.value.length <= 3) {
            this.value = this.value.replace (/^(\d{0,3})/, '$1');
        } else if (this.value.length <= 6) {
            this.value = this.value.replace (/^(\d{0,3})(\d{0,3})/, '$1-$2');
        } else {
            this.value = this.value.replace (/^(\d{0,3})(\d{0,3})(.*)/, '$1-$2-$3');
        }

        this.ngModelChange.emit(this.value);
        // set the new value
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        const current: string = this.el.nativeElement.textContent;
        const  next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }

    }
}
