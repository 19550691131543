<div class="foot-wrapper">

	<!-- Card Set //-->
	<div class="foot-divider"></div>
	<div class="foot-row" *ngIf="footerService && footerService.footerCards">
		<div class="foot-column" *ngFor="let card of footerService.footerCards; let i = index">
			<div class="foot-cell">
			<!-- Card 1 //-->
			<div class="foot-card-title">
			{{card?.Title}}
			</div>
			<div class="foot-card-content" [innerHTML] = "card.Body"></div>
			</div>
		</div>
	</div>
	<div class="foot-divider"></div>
</div>
