
import { Component, OnInit, Input } from '@angular/core';
import {ErrorService} from "../shared/services/error.service";

@Component({
    selector: 'error-summary',
    templateUrl: './error-summary.component.html'
})
export class ErrorSummaryComponent implements OnInit {
   
    constructor(public errorService: ErrorService) { }

    ngOnInit() {
    }

}