<!-- BEGIN current pcp-->
<div [ngClass]="{'hdrow-md-12':properties.ShowHorizontalLine, 'nohdrow':!properties.ShowHorizontalLine}">
        <h2>{{properties.HeaderText || ""}}</h2>
        <div class="row" *ngIf="properties.ShowProviderId">
            <div class="col-md-6 pcp-info-box">
                <label class="control-label">
                    {{properties.PCPProviderIdLabelText || "provider id"}}
                </label>
                <div>{{provider.ProviderId}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 closer-group-info-box">
                <label class="control-label">{{properties.PCPNameLabelText || "pcp Name:"}}</label>
                <div>
                    <app-provider-link *ngIf="provider"  
                        providerName="{{provider.ProviderName}}"
                        lineOfBusinessCode="{{provider.LOBCode}}"
                        providerId="{{provider.ProviderId}}"
                        healthNetworkCode={{healthNetworkCode}}>
                    </app-provider-link>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 contact-info-address-box">
                <label class="control-label">{{properties.AddressLine1LabelText || "address 1:"}}</label>
                <app-address 
                    [properties]="providerAddress.properties" 
                    [address]="providerAddress.address">
                </app-address>
            </div>
        </div>
    
        <div class="row">
            <div class="col-md-6 pcp-info-box">
                <label class="control-label">{{properties.PhoneNumber|| "phone number" }}</label>
                <div>{{provider.PhoneNumber}}</div>
            </div>
        </div>
</div>
    <!-- END current pcp-->