<div class="form-group">
  <label class="col-form-label" for="{{ phoneProperties.Name }}">
    {{ phoneProperties.LabelText || "mobile phone" }}
  </label>
  <input
    id="{{ phoneProperties.Name }}"
    name="{{ phoneProperties.Name }}"
    type="text"
    class="form-control"
    #PhoneValue="ngModel"
    maxlength="12"
    pattern="^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$"
    [forbiddenNumbers]
    tabindex="{{ phoneProperties.TabIndex }}"
    placeholder="{{ phoneProperties.Placeholder || 'enter mobile phone' }}"
    [ngClass]="{
      'is-invalid':
        (PhoneValue.hasError('forbiddenPhone') ||
          PhoneValue.invalid ||
          (phoneProperties.Value.length == 0 && phoneProperties.IsRequired)) &&
        PhoneValue.touched &&
        PhoneValue.dirty
    }"
    [ngModel]="phoneProperties.Value"
    (ngModelChange)="
      onPhoneChange(
        $event,
        PhoneValue.invalid && (PhoneValue.touched || PhoneValue.dirty)
      )
    "
    phone
  />

  <label
    ID="phoneErrorMsg"
    *ngIf="
      PhoneValue.hasError('forbiddenPhone') ||
      (PhoneValue.invalid && PhoneValue.touched && PhoneValue.dirty)
    "
    class="invalid-feedback"
  >
    {{ phoneProperties.ErrorMessage || "invalid phone" }}
  </label>
  <label
    ID="phoneErrorMsg"
    *ngIf="
      phoneProperties.Value.length == 0 &&
      phoneProperties.IsRequired &&
      PhoneValue.touched &&
      PhoneValue.dirty
    "
    class="invalid-feedback"
  >
    {{ phoneProperties.RequiredMessage || "required" }}
  </label>
</div>
