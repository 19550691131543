import { Component, OnInit, Input } from '@angular/core';
import { AccordionData } from '../../member-portal/shared/survey';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.scss'
})
export class AccordionComponent implements OnInit {
  @Input()
  headerLabel: string;
  @Input()
  items: AccordionData[];

  openSection: number | null = null;

  constructor() {}

  ngOnInit() {}

  toggleSection(index: number) {
    if (this.openSection === index) {
      this.openSection = null; // Close the currently open section
    } else {
      this.openSection = index; // Open the selected section
    }
  }
}
