


<div 
  class="form-group"
  [ngClass]="{'is-invalid': (DateOfBirthMonth.invalid && (DateOfBirthMonth.touched || DateOfBirthMonth.dirty)) ||
                            (DateOfBirthDD.invalid && (DateOfBirthDD.touched || DateOfBirthDD.dirty)) ||
                            ((DateOfBirthYear.invalid || DateOfBirthYear.invalid) && (DateOfBirthYear.touched || DateOfBirthYear.dirty))}">
  <label class="col-form-label">
    {{dateOfBirthProperties.LabelText || "date of birth"}}
  </label>
  <div class="row">
    <div class="col-md-12">
      
      <span style="white-space: nowrap">
        <input
          id="{{dateOfBirthProperties.MonthName}}"
          name="{{dateOfBirthProperties.MonthName}}"
          type="text"
          class="form-control" 
          tabindex="{{dateOfBirthProperties.TabIndexMonth}}"
          pattern="^(0[1-9]|1[0-2]|[1-9])$"
          maxlength="2"
          style="display:inline;width:70px;margin-right:10px"
          placeholder="MM"
          #DateOfBirthMonth="ngModel"
          [ngClass]="{'is-invalid': DateOfBirthMonth.invalid && (DateOfBirthMonth.touched && DateOfBirthMonth.dirty)}"
          [(ngModel)]="dateOfBirthProperties.Month"
          (ngModelChange)="onDobMonthChange(DateOfBirthMonth.invalid && (DateOfBirthMonth.touched || DateOfBirthMonth.dirty))" 
          required>
      </span>

      <span style="white-space: nowrap">
        <input
          id="{{dateOfBirthProperties.DayName}}"
          name="{{dateOfBirthProperties.DayName}}"
          type="text"
          class="form-control" 
          tabindex="{{dateOfBirthProperties.TabIndexDay}}"
          pattern="^([1-9]|0[1-9]|[12]\d|3[01])$"
          placeholder="DD"
          maxlength="2"
          style="display:inline;width:70px;margin-left:10px;margin-right:10px"
          #DateOfBirthDD="ngModel"
          [ngClass]="{'is-invalid': DateOfBirthDD.invalid && (DateOfBirthDD.touched && DateOfBirthDD.dirty)}"
          [(ngModel)]="dateOfBirthProperties.Day"
          (ngModelChange)="onDobDayChange(DateOfBirthDD.invalid && (DateOfBirthDD.touched || DateOfBirthDD.dirty))" 
          required>
      </span>
      
      <span style="white-space: nowrap">
        <input
          id="{{dateOfBirthProperties.YearName}}"
          name="{{dateOfBirthProperties.YearName}}"
          type="text"
          class="form-control" 
          pattern="^\d{4}"
          style="display:inline; width:100px; margin-left:10px;margin-right:10px"
          tabindex="{{dateOfBirthProperties.TabIndexYear}}"
          placeholder="YYYY"
          #DateOfBirthYear="ngModel"
          [ngClass]="{'is-invalid': (isYearInvalid || DateOfBirthYear.invalid) && (DateOfBirthYear.touched && DateOfBirthYear.dirty)}"
          [(ngModel)]="dateOfBirthProperties.Year"
          (ngModelChange)="onDobYearChange((DateOfBirthYear.invalid || DateOfBirthYear.invalid) && (DateOfBirthYear.touched || DateOfBirthYear.dirty))" 
          required>
      </span>

      <label 
        id="{{dateOfBirthProperties.ErrorMsgName}}" 
        *ngIf="(DateOfBirthMonth.invalid && (DateOfBirthMonth.touched && DateOfBirthMonth.dirty)) ||
        (DateOfBirthDD.invalid && (DateOfBirthDD.touched && DateOfBirthDD.dirty)) ||
        (isYearInvalid && (DateOfBirthYear.touched && DateOfBirthYear.dirty))"
        class="invalid-feedback">
        {{dateOfBirthProperties.ErrorMessage ||'Error'}}
      </label> 
      <label 
        id="dobErrorMsgRange" 
        *ngIf="!isCorrectRange && (DateOfBirthMonth.touched && DateOfBirthMonth.dirty && DateOfBirthDD.touched && DateOfBirthDD.dirty && DateOfBirthYear.touched && DateOfBirthYear.dirty)" 
        class="invalid-feedback">
        {{dateOfBirthProperties.DateRangeErrorMessage}}
      </label> 
    </div>
  </div>
</div>


  
  