import { Directive, Input } from "@angular/core";
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { environment } from "../../../../environments/environment";

@Directive({
  selector: "[forbiddenNumbers]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ForbiddenPhonesValidatorDirective,
      multi: true,
    },
  ],
})
export class ForbiddenPhonesValidatorDirective implements Validator {
  @Input() forbiddenNumbers: string[] = environment.forbiddenNumbers;

  validate(control: AbstractControl): ValidationErrors | null {
    const regex = /^[01]/;
    return this.forbiddenNumbers.includes(control.value) ||
      regex.test(control.value)
      ? { forbiddenPhone: { value: control.value } }
      : null;
  }
}
