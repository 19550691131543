export class NavigatorSitecoreTemplate {
  public HomeRouteLabel: string;
  public MemberProfileRouteLabel: string;
  public OnlineServicesLabel: string;
  public RequestIdCardRouteLabel: string;
  public UserProfileRouteLabel: string;
  public LogoutRouteLabel: string;
  public PrintIdCardRoute: string;
  public GeneralInquiryRouteLabel: string;
  public InterpretiveServiceRouteLabel: string;
  public UpdateMemberProfileRouteLabel: string;
  public ImportantLinksRouteLabel: string;
  public FindProviderLabel: string;
  public SearchForProviderRouteLabel: string;
  public SearchForFacilityRouteLabel: string;
  public SearchForBehavioralHealthRouteLabel: string;
  public SearchForPharmacyRouteLabel: string;
  public SearchForVisionRouteLabel: string;
  public AuthorizationsRouteLabel: string;
  public HealthWellnessRouteLabel: string;
  public ChangePcpChangeHealthNetworkRouteLabel: string;
  public PharmacyBenefitToolRouteLabel: string;
  public SearchForUrgentRouteLabel: string;
  public SurveysRouteLabel: string;
  public HealthInfoFormRouteLabel: string;
  public VirtualVisitsRouteLabel: String
}
