<div class="container">
    <h2>Site Map</h2>
    <ul>
        <li><a routerLink="/" routerLinkActive="active">Home</a></li>
        <li><a routerLink="/dashboard" routerLinkActive="active">Dashboard</a></li>
        <li><a routerLink="/authorizations" routerLinkActive="active">Authorizations</a></li>
        <li><a routerLink="/claims" routerLinkActive="active">Claims</a></li>
        <li>
            Nested Pages
            <ul>
                <li>
                    <a routerLink="/api-helper" routerLinkActive="active">Page 1</a>
                </li>
                <li>
                    <a routerLink="/style-guide" routerLinkActive="active">Page 2</a>
                </li>
            </ul>
        </li>
        <li><a routerLink="/about" routerLinkActive="active">About</a></li>
    </ul>
</div>