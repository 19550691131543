import { Address } from './address';
import { PrimaryCareProvider } from './primary-care-provider';
import { LineOfBusiness } from './line-of-business';
import { HealthNetworkPlan } from './health-network-plan';
import { MemberLanguages } from './member-languages';
import { PersonalRepresentative } from './personal-representative';

export class MemberProfile {
    constructor() {
        this.HomeAddress = new Address();
        this.MailingAddress = new Address();
        this.LOB = new LineOfBusiness();
        this.HealthNetworkPlan = new HealthNetworkPlan();
        this.PrimaryCareProviderInfo = new PrimaryCareProvider();
        this.PrimaryCareProviderInfo.Address = new Address();
        this.PreferredSpokenLanguage = new MemberLanguages();
        this.PreferredWrittenLanguage = new MemberLanguages();
        this.PreferredFormatLanguage = new MemberLanguages();
    }

    public CIN: string;
    public IsEligible: boolean;
    public EligibilityStatus: string;
    public ExternalUserId: string;
    public FirstName: string;
    public MiddleName: string;
    public LastName: string;
    public DateOfBirth: Date;
    public Gender: string;
    public HealthNetworkClassCode: string;
    public HomeAddress: Address;
    public MailingAddress: Address;
    public HomePhoneNumber: string;
    public AlternatePhoneNumber: string;
    public MobilePhoneNumber: string;
    public Email: string;
    public LOB: LineOfBusiness;
    public GroupId: number;
    public SubGroupId: number;
    public ShareOfCost: string;
    public PreferredWrittenLanguage: MemberLanguages;
    public PreferredSpokenLanguage: MemberLanguages;
    public PreferredFormatLanguage: MemberLanguages;
    public HealthNetworkPlan: HealthNetworkPlan;
    public PrimaryCareProviderInfo: PrimaryCareProvider;
    public HasRestrictedAidCode: boolean;
    public HasBCCTPAidCode: boolean;
    public HasGivenSSAConsentForPrimaryPhone: boolean;
    public HasAcceptedSSAForPrimaryAddressConsent: boolean;
    public HasAcknowledgedTermsOfUse: boolean;
    public ChangePCPEffectiveDate: Date;
    public CalculatedChangePCPEffectiveDate: Date;
    public MemberHasRestrictedVipCode: boolean;
    public IsMinor: boolean;
    public HasAccessToMedImpact: boolean;
    public UserProfileEmail: string;
    public TCPAValue: number;
    public TCPAShowSubmissionLimitMessage: boolean;
    public PersonalRepresentative: PersonalRepresentative;
}
