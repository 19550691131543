import { Component, OnInit, Input, OnDestroy, forwardRef } from '@angular/core';
import { DatePickerProperties } from '../shared/date-picker-properties';
import { ControlValueAccessor, Validator, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormControl } from '@angular/forms';
import moment from 'moment';


@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    }
  ],
})
export class DatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  @Input() datePickerProperties = new DatePickerProperties();

  // Start form control interface implements
  @Input() name: string;

  private innerValue: Date;

  onChange: any = (_: any) => { };
  onTouched: any = () => { };

  get value() {
    return this.innerValue;
  }

  set value(val) {
    if (this.innerValue !== val) {
      this.innerValue = val;
      this.onChange(val);
      // this.onTouched();
    }
  }
  //Set touched on blur
  onBlur() {
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  //set input value from caller component.
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      if (value) {
        this.day = value.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
        this.month = (value.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
        this.year = value.getFullYear().toString();
      }
    }
  }

  validate({ value }: UntypedFormControl) {
    let strDate = `${this.year}-${this.month}-${this.day}T00:00:00`;

    var m = moment(strDate);
    if (!m.isValid()) {
      this.errorMessage = 'InvalidDate';
      return { 'InvalidDate': true };
    }

    let tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow.setDate(tomorrow.getDate() + 1);


    if (this.value < tomorrow) {
      this.errorMessage = 'NotFutureDate';
      return { 'NotFutureDate': true };
    }

    this.errorMessage = "";
    return null;
  }
  // End form control interface implements

  month: string; //01 => 12
  day: string; //01 => 31
  year: string; // 1900 => ..

  months: Array<string> = new Array<string>();
  days: Array<string> = new Array<string>();
  years: Array<string> = new Array<string>();

  isValidating: boolean = false;
  errorMessage: string = "";

  constructor() { }

  ngOnInit() {
    this.populateCollections();
  }
  
  ngOnDestroy() {

  }

  populateCollections() {

    // Months collection
    for (let i = 1; i <= 12; i++) {
      this.months.push((i).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }));
    }

    // Days collection
    for (let i = 1; i <= 31; i++) {
      this.days.push((i).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }));
    }
    // Years collection
    let thisYear = (new Date()).getFullYear();
    this.years.push(thisYear.toString());
    this.years.push((thisYear + 1).toString());
    this.years.push((thisYear + 2).toString());

  }

  onDateChange(newValue) {
    try {
      let strDate = `${this.year}-${this.month}-${this.day}T00:00:00`;
      this.value = new Date(strDate);
    } catch (ex) {
      throw ex;
    }
  }

}
