<div *ngIf="!viewProperties.ReadOnly" class="email-phone-fixed-box">
    <form #emailForm="ngForm">
        <fieldset>
            <div class="email-phone-fixed-title">
                <h4>{{viewProperties.Title || "your email address is"}}</h4>
            </div>
            <div class="form-group" [ngClass]="{'is-invalid': (email.hasError('pattern') || email.hasError('required') || email.hasError('maxlength')) && email.touched && email.dirty }">
                <div  class="email-phone-fixed-title">
                    <label for="email" class="control-label">{{viewProperties.FieldLabel || "email address"}}</label>
                </div>
                <input 
                    id="email" 
                    [(ngModel)]="emailText" 
                    name="email" 
                    type="email" 
                    #email="ngModel"
                    class="form-control" 
                    maxlength="512"
                    [ngClass]="{'is-invalid': 
                        (email.hasError('pattern') || email.hasError('required') || email.hasError('maxlength')) && 
                        email.touched && 
                        email.dirty }"
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" required />

                <label *ngIf="email.hasError('pattern') && email.touched && email.dirty" 
                    class="invalid-feedback"
                    for="email">
                    {{ validationMessages.InvalidInputMessage || 'email is invalid.'}}
                </label>
                <label *ngIf="email.hasError('required') && email.touched && email.dirty" 
                    class="invalid-feedback"
                    for="email">
                    {{ validationMessages.RequiredMessage || 'email is required.'}}
                </label>
                <label *ngIf="email.hasError('maxlength') && email.touched && email.dirty" 
                    class="invalid-feedback" 
                    for="email">
                    {{ validationMessages.MaxLengthMessage ||'email has exceeded the number of characters allowed.'}}
                </label>
            </div>
            <div class="row">
                <div class="email-phone-fixed-footer">
                    <div class="col-12 text-right">
                        <button class="btn btn-link" (click)="resetView();">{{viewProperties.CancelButtonText || "cancel"}}</button>
                        <button 
                            class="btn btn-primary" 
                            type="submit" 
                            (click)="updateEmail()" 
                            [disabled]="!emailForm.form.valid || (!email.dirty || (emailText == oldValue))">
                            {{viewProperties.SubmitButtonText || "update"}}
                        </button>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
    </div>
    <div *ngIf="viewProperties.ReadOnly">
        <h4 class="email-phone-fixed-title">{{viewProperties.Title || "your email address is"}}</h4>
        <div class="email-info-box">
            <label class="email-phone-fixed-title">{{viewProperties.FieldLabel || "email address"}}</label>
            <div>{{emailText}}</div>
        </div>
</div>
    