export class SiteFooterTemplate{
    public LOBContent : string;
    public AdobeAcquisition : string;
    public CaloptimaLabel : string;
    public CaloptimaUrl : string;
    public ContactUsLabel : string;
    public ContactUsUrl : string;
    public CommonFormsLabel : string;
    public CommonFormsUrl : string;
    public ResourcesLabel : string;
    public ResourcesUrl : string;
    public TermsOfUseLabel : string;
    public TermsOfUseUrl : string;
    public WebsitePrivacyPolicyLabel : string;
    public WebsitePrivacyPolicyUrl : string;
    public NoticeOfPrivacyPracticesLabel : string;
    public NoticeOfPrivacyPracticesUrl : string;
    public ReportFraudAbuseLabel : string;
    public ReportFraudAbuseUrl : string;
    public Copyright : string;
}