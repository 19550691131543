import { Injectable, Inject } from '@angular/core';
import { State } from '../state';
import moment from 'moment';

@Injectable()
export class CommonService {

    public statesList: Array<State>;

    public getStates(): Array<State>  {
        this.statesList = [
            {
                "name": "Alabama",
                "abbreviation": "AL"
            },
            {
                "name": "Alaska",
                "abbreviation": "AK"
            },
            {
                "name": "American Samoa",
                "abbreviation": "AS"
            },
            {
                "name": "Arizona",
                "abbreviation": "AZ"
            },
            {
                "name": "Arkansas",
                "abbreviation": "AR"
            },
            {
                "name": "California",
                "abbreviation": "CA"
            },
            {
                "name": "Colorado",
                "abbreviation": "CO"
            },
            {
                "name": "Connecticut",
                "abbreviation": "CT"
            },
            {
                "name": "Delaware",
                "abbreviation": "DE"
            },
            {
                "name": "District Of Columbia",
                "abbreviation": "DC"
            },
            {
                "name": "Federated States Of Micronesia",
                "abbreviation": "FM"
            },
            {
                "name": "Florida",
                "abbreviation": "FL"
            },
            {
                "name": "Georgia",
                "abbreviation": "GA"
            },
            {
                "name": "Guam",
                "abbreviation": "GU"
            },
            {
                "name": "Hawaii",
                "abbreviation": "HI"
            },
            {
                "name": "Idaho",
                "abbreviation": "ID"
            },
            {
                "name": "Illinois",
                "abbreviation": "IL"
            },
            {
                "name": "Indiana",
                "abbreviation": "IN"
            },
            {
                "name": "Iowa",
                "abbreviation": "IA"
            },
            {
                "name": "Kansas",
                "abbreviation": "KS"
            },
            {
                "name": "Kentucky",
                "abbreviation": "KY"
            },
            {
                "name": "Louisiana",
                "abbreviation": "LA"
            },
            {
                "name": "Maine",
                "abbreviation": "ME"
            },
            {
                "name": "Marshall Islands",
                "abbreviation": "MH"
            },
            {
                "name": "Maryland",
                "abbreviation": "MD"
            },
            {
                "name": "Massachusetts",
                "abbreviation": "MA"
            },
            {
                "name": "Michigan",
                "abbreviation": "MI"
            },
            {
                "name": "Minnesota",
                "abbreviation": "MN"
            },
            {
                "name": "Mississippi",
                "abbreviation": "MS"
            },
            {
                "name": "Missouri",
                "abbreviation": "MO"
            },
            {
                "name": "Montana",
                "abbreviation": "MT"
            },
            {
                "name": "Nebraska",
                "abbreviation": "NE"
            },
            {
                "name": "Nevada",
                "abbreviation": "NV"
            },
            {
                "name": "New Hampshire",
                "abbreviation": "NH"
            },
            {
                "name": "New Jersey",
                "abbreviation": "NJ"
            },
            {
                "name": "New Mexico",
                "abbreviation": "NM"
            },
            {
                "name": "New York",
                "abbreviation": "NY"
            },
            {
                "name": "North Carolina",
                "abbreviation": "NC"
            },
            {
                "name": "North Dakota",
                "abbreviation": "ND"
            },
            {
                "name": "Northern Mariana Islands",
                "abbreviation": "MP"
            },
            {
                "name": "Ohio",
                "abbreviation": "OH"
            },
            {
                "name": "Oklahoma",
                "abbreviation": "OK"
            },
            {
                "name": "Oregon",
                "abbreviation": "OR"
            },
            {
                "name": "Palau",
                "abbreviation": "PW"
            },
            {
                "name": "Pennsylvania",
                "abbreviation": "PA"
            },
            {
                "name": "Puerto Rico",
                "abbreviation": "PR"
            },
            {
                "name": "Rhode Island",
                "abbreviation": "RI"
            },
            {
                "name": "South Carolina",
                "abbreviation": "SC"
            },
            {
                "name": "South Dakota",
                "abbreviation": "SD"
            },
            {
                "name": "Tennessee",
                "abbreviation": "TN"
            },
            {
                "name": "Texas",
                "abbreviation": "TX"
            },
            {
                "name": "Utah",
                "abbreviation": "UT"
            },
            {
                "name": "Vermont",
                "abbreviation": "VT"
            },
            {
                "name": "Virgin Islands",
                "abbreviation": "VI"
            },
            {
                "name": "Virginia",
                "abbreviation": "VA"
            },
            {
                "name": "Washington",
                "abbreviation": "WA"
            },
            {
                "name": "West Virginia",
                "abbreviation": "WV"
            },
            {
                "name": "Wisconsin",
                "abbreviation": "WI"
            },
            {
                "name": "Wyoming",
                "abbreviation": "WY"
            }
        ]

        return this.statesList;
    }

    public isAge18OrOver(dateValue: string ): boolean {
        const dob = new Date(dateValue);
        const birthdate = moment(dob);
        const now = moment(Date.now());
        const age = now.diff(birthdate, 'years');

        if (age >= 18) {
            return true;
        } else {
            return false;
        }
    }

    public isAgeUnder18(dateValue: string ): boolean {
        const dob = new Date(dateValue);
        const birthdate = moment(dob);
        const now = moment(Date.now());
        const age = now.diff(birthdate, 'years');

        if (age < 18) {
            return true;
        } else {
            return false;
        }
    }

    public IsValidUserNamePasswordCombo(string1: string, string2: string): boolean {

        if (this.checkCaselessString(string1, string2)) {
            return false;
        }

        return true;
    }

    public checkCaselessString(string1: string, string2: string): boolean {

        if ( !string1 || !string2 ) {
            return false;
        }

        if (string1.toUpperCase() === string2.toUpperCase()) {
            return true;
        }

        return false;
    }

    public addZero(x, n) {
        while (x.toString().length < n) {
            x = '0' + x;
        }
        return x;
    }

    public LogWithTime(message) {
        const d = new Date();

        const h = this.addZero(d.getHours(), 2);
        const m = this.addZero(d.getMinutes(), 2);
        const s = this.addZero(d.getSeconds(), 2);
        const ms = this.addZero(d.getMilliseconds(), 3);

        console.log(message + ' ' + h + ':' + m + ':' + s + ':' + ms);
    }

    public GetPhoneValue(phoneDisplay)
    {
        if(phoneDisplay)
        {
            return phoneDisplay.replace(/\D/g,'');
        }
        else
        {
            return phoneDisplay;            
        }
    }

    public getListOfInvalidPhoneNumbers() {
        return [
            "0000000000",
            "1234567890",
            "2223334444",
            "7142468400"
        ];
    }

    public isInvalidPhoneNumber(phoneNumber: string): boolean {
        phoneNumber = phoneNumber.replace(/\(/g, '');
        phoneNumber = phoneNumber.replace(/\)/g, '');
        phoneNumber = phoneNumber.replace(/-/g, '');
        if (phoneNumber.length < 10) {
            return true;
        }
        return this.getListOfInvalidPhoneNumbers().some( currentValue => currentValue == phoneNumber);
    }
}
