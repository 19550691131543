import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';


import { HeaderSitecoreTemplate } from '../header-sitecore-template';
import { Configuration } from '../../../app.constants';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import {publishReplay,refCount,catchError} from 'rxjs/operators';
import { LanguageService } from './language.service';
@Injectable()
export class HeaderSitecoreService {

    constructor(private http: HttpClient, private configuration: Configuration, private languageService: LanguageService) {
    }

    getHeaderSitecoreContent(): Observable<HeaderSitecoreTemplate> {
        var errorResponse;
        return this.http.get<HeaderSitecoreTemplate>(
            this.languageService.getContentServiceUrlWithParameter(this.configuration.HeaderContentServiceParameter)
            ).pipe(
            publishReplay(1),
            refCount(),
            catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }
}
