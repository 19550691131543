import { Component, Input, Provider, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Configuration } from '../../../../app.constants';
import { ProviderLinkService } from '../../services/provider-link.service';
import { MemberLobProviderLinksModel } from '../../member-lob-provider-sitecore-template';
import shajs from 'sha.js';

@Component({
    selector: 'app-provider-link',
    templateUrl: './provider-link.component.html',
    styleUrls: ['./provider-link.component.scss'],
    providers: [DatePipe, ProviderLinkService]
})
export class ProviderLinkComponent implements OnInit {
    @Input() healthNetworkCode: string;
    @Input() providerName: string ;
    @Input() providerId: string;
    @Input() lineOfBusinessCode: string;
    @Input() providerUrl: MemberLobProviderLinksModel;

    private hashedDate: string;
    public providerLink: string;
    private today = new Date();
    private stringToHash = this.datepipe.transform(this.today, this.configuration.DateHashFormat);

    constructor(private providerLinkService: ProviderLinkService,
        private configuration: Configuration,
        private datepipe: DatePipe) {
    }

    ngOnInit() {
      this.hashedDate = shajs(this.configuration.ShaVersion).update(this.stringToHash.toString()).digest(this.configuration.DigestString);
    }

    ngOnChanges(providerChange: Provider) {
      this.getProviderSearchLink();
    }

    isKaiser(): boolean {
      let result: boolean = false;
      
      if ((this.healthNetworkCode?.toLocaleUpperCase() === this.configuration.KaiserCodeRegular)
          || (this.healthNetworkCode?.toLocaleUpperCase() === this.configuration.KaiserCodeExpansion)
      ) {
          result = true;
      }

      return result;
  }
    getProviderSearchLink() {
      if (this.lineOfBusinessCode && this.providerId) {
        this.providerLinkService.getMemberLobProviderLinks(this.lineOfBusinessCode)
          .subscribe(result => {
            this.providerUrl = result;
            this.providerLink = this.providerLinkService.getProviderDetailsUrl(
              this.providerUrl.ProviderDetailSitecoreLink,
              this.providerId,
              this.hashedDate);
          });
      }
    }
}
