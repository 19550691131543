
<ul class="navbar-nav  navbar-mega">
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownLanguage" role="button" data-bs-toggle="dropdown"
       aria-haspopup="true" aria-expanded="false">
       <i class="fa-duotone fa-language fa-nav-lob"></i>&nbsp;{{selectedLanguage}}
    </a>
    <ul class="dropdown-menu topnav-top" aria-labelledby="navbarDropdownLanguage">

      <li *ngFor="let language of languageMenuItems" data-bs-dismiss="offcanvas" class="dropdown-item" href="#"
          (click)="setLanguage(language)">{{language?.NameLabel}}</li>
    </ul>
  </li>
</ul>
