export class PCPInfoViewProperties {
    public PCPNameLabelText: string;
    public PhoneNumber: string;
    public HeaderText: string;
    public AddressLine1LabelText: string;
    public AddressLine2LabelText: string;
    public CityLabelText: string;
    public StateLabelText: string;
    public ZipCodeLabelText: string;
    public ShowHorizontalLine: boolean = true;
    public ShowProviderId: boolean = false;
    public PCPProviderIdLabelText: string;
    
}