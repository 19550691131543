import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { DateOfBirthProperties } from '../shared/date-of-birth-properties';


@Component({
  selector: 'app-date-of-birth',
  templateUrl: './date-of-birth.component.html',
  styleUrls: ['./date-of-birth.component.scss']
})
export class DateOfBirthComponent implements OnInit, OnChanges {

  @Input() dateOfBirthProperties = new DateOfBirthProperties();
  @Output() dateOfBirthEValue = new EventEmitter();

  // public month: number;
  // public day: number;
  // public year: number;

  public monthName: string;
  public dayName: string;
  public yearName: string;

  public isMonthInvalid: boolean;
  public isDayInvalid: boolean;
  public isYearInvalid: boolean;

  public lowerEndYear: number = 1900;
  public upperEndYear: number = (new Date()).getFullYear();

  public isCorrectRange: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  private buildDateString(): void {
    if (this.dateOfBirthProperties.Day !== undefined &&
        this.dateOfBirthProperties.Month !== undefined &&
        this.dateOfBirthProperties.Year !== undefined) {
          var finalMonth:string = this.dateOfBirthProperties.Month.toString();
          var finalDay:string = this.dateOfBirthProperties.Day.toString();

          if (String(this.dateOfBirthProperties.Month).length === 1) {
            finalMonth = '0' + finalMonth;
          }

          if (String(this.dateOfBirthProperties.Day).length === 1) {
            finalDay = '0' + finalDay;
          }

          this.dateOfBirthProperties.Value = finalMonth + '/' + finalDay + '/' + this.dateOfBirthProperties.Year;
    }
  }

  onDobChange(isInValid: boolean) {
    this.buildDateString();

    if (this.dateOfBirthProperties.Value !== undefined) {
      if (this.dateOfBirthProperties.IsCorrectRange) {
        this.isCorrectRange = this.dateOfBirthProperties.IsCorrectRange(this.dateOfBirthProperties.Value);
      } else {
        this.isCorrectRange = true;
      }

      this.dateOfBirthEValue.emit({
        dateOfBirthValue: this.dateOfBirthProperties.Value,
        isValid: !isInValid,
        isCorrectRange: this.isCorrectRange
      });
    }
  }

  public onDobMonthChange(isInvalid: boolean): void {
    if (String(this.dateOfBirthProperties.Month).length === 2) {
      (document.querySelector('#' + this.dateOfBirthProperties.DayName) as HTMLInputElement ).focus();
    } 

    this.onDobChange(isInvalid);
  }

  public onDobDayChange(isInvalid: boolean ): void {
    if (String(this.dateOfBirthProperties.Day).length === 2) {
      (document.querySelector('#' + this.dateOfBirthProperties.YearName) as HTMLInputElement ).focus();
    }
    this.onDobChange(isInvalid);
  }

  public onDobYearChange(isInvalid: boolean ): void {

    if ((this.dateOfBirthProperties.Year < this.lowerEndYear || this.dateOfBirthProperties.Year > this.upperEndYear) || isInvalid) {
      this.isYearInvalid = true;
    } else {
      this.isYearInvalid = false;
    }

    this.onDobChange(this.isYearInvalid);
  }
}
