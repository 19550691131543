export class DateOfBirthProperties {

    public Value: string;
    public Placeholder: string;
    public MonthName: string;
    public DayName: string;
    public YearName: string;
    public ErrorMsgName: string;
    public LabelText: string;
    public ErrorMessage: string;
    public TabIndexMonth: string;
    public TabIndexDay: string;
    public TabIndexYear: string;
    public IsCorrectRange: Function;
    public DateRangeErrorMessage: string;
    public Month: number;
    public Day: number;
    public Year: number;
}
