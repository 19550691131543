import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AddressViewProperties } from '../../address-view-properties';
import { Address } from '../../../../member-portal/shared/address';
import { UppercaseInputDirective } from '../../directives/uppercase-input.directive';
// import { InputSwitchModule } from 'primeng/inputswitch';
import { ZipCodeMaskDirective } from '../../directives/zip-code-mask.directive';
import { ZipCodePipe } from '../../pipes/zipcode.pipe';
import {CommonService} from '../../services/common.service';
import {State} from '../../state';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [CommonService]
})

export class AddressComponent implements OnInit {

    @Input() properties: AddressViewProperties = new AddressViewProperties();
    @Input() address: Address = new Address();
    @Output() update: EventEmitter<any> = new EventEmitter();
    @Output() cancel: EventEmitter<any> = new EventEmitter();
    @Output() Error: EventEmitter<any> = new EventEmitter();
    @Output() sSAConsentChanged: EventEmitter<any> = new EventEmitter();
    initialAddress: Address = new Address();
    SSAConsent: boolean = true;
    States: Array<State> = new Array<State>();
    constructor(private StatesService: CommonService ) { }

    ngOnInit() {
        this.States = this.StatesService.getStates();
    }

    onUpdate() {
        var zipCodeDigits = this.address.ZipCode.match(/\d/g).join("");
        this.address.ZipCode = zipCodeDigits;
        this.update.emit(this.address);
    }

    onCancel() {
        this.cancel.emit(null);
    }

    addressLine1OnChange(control) {
        if (control.viewModel != null) {
            this.properties.AddressLine1CharacterCount = control.viewModel.length;
            this.setMaxLengthError(control);
        } else {
            this.properties.AddressLine1CharacterCount = 0;
        }
    }

    addressLine2OnChange(control) {
        if (control.viewModel != null) {
            this.properties.AddressLine2CharacterCount = control.viewModel.length;
            this.setMaxLengthError(control);
        }
        else {
            this.properties.AddressLine2CharacterCount = 0;
        }
    }

    cityOnChange(control) {

        if (control.viewModel != null) {
            this.properties.CityCharacterCount = control.viewModel.length;
            this.setMaxLengthError(control);
        }
        else {
            this.properties.CityCharacterCount = 0;
        }
    }

    setMaxLengthError(control) {
        if (control.viewModel != null) {
            if (control.viewModel.length > this.properties.AddressLineMaxNumberOfCharactersAllowed) {
                this.Error.emit(control._parent.controls[control.name].setErrors({ 'maxlength': true }));
            }
        }
        else {
            this.Error.emit(control._parent.controls[control.name].setErrors({ 'maxlength': null }));
        }
    }

    inputSwitchChanged() {
        this.sSAConsentChanged.emit(this.SSAConsent);
    }
}
