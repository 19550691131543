
<div class="form-group">
    <!-- Date -->
    <label class="col-form-label"> {{datePickerProperties?.DateLabel || 'date (mm/dd/yyyy)'}}</label>
    <div class="d-flex" [ngClass]="{'is-invalid': errorMessage}">
        <!-- Month -->
        <div class="date-part mr-4">
            <select [(ngModel)]="month" name="month" class="form-select"
            (ngModelChange)="onDateChange($event)"
            [ngClass]="{'is-invalid': errorMessage}"
                >
                <option [value]="mm" *ngFor="let mm of months">
                    {{mm}}
                </option>
            </select>
        </div>

        

        <!-- Day -->
        <div class="date-part mr-4">
            <select [(ngModel)]="day" name="day" class="form-select"
            (ngModelChange)="onDateChange($event)"
            [ngClass]="{'is-invalid': errorMessage}"
                >
                <option [value]="dd" *ngFor="let dd of days">
                    {{dd}}
                </option>
            </select>
        </div>

        <!-- Year -->
        <div class="date-part">
            <select [(ngModel)]="year" name="year" class="form-select" 
            (ngModelChange)="onDateChange($event)"
            [ngClass]="{'is-invalid': errorMessage}"
                >
                <option [value]="yyyy" *ngFor="let yyyy of years">
                    {{yyyy}}
                </option>
            </select>
        </div>

    </div>
    <label class="invalid-feedback" *ngIf="errorMessage === 'NotFutureDate'">
        {{datePickerProperties?.DateRangeError || 'please select a future date.'}}
    </label>
    <label class="invalid-feedback" *ngIf="errorMessage === 'InvalidDate'">
        {{datePickerProperties?.InvalidDateError || 'selected date is not valid.'}}
    </label>
</div>