/*
** Component to receive redirect from AuthGuard Service. Component then uses
** the OAuth handler library to redirect to IdP with correct URL parameters
*/
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Configuration } from '../../app.constants';


@Component({
    selector: 'app-authorize',
    templateUrl: './authorize.component.html',
    styleUrls: ['./authorize.component.scss']
})

export class AuthorizeComponent implements OnInit{

    constructor(private config:Configuration){}

    ngOnInit() {
        window.location.href = this.config.LoginUrl;

      }
    
}