import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { PhonePipe } from "../../pipes/phone.pipe";
import { PhoneViewProperties } from "../../phone-view-properties";
import { PhoneValidationMessages } from "../../phone-validation-messages";
import { InputSwitchModule } from "primeng/inputswitch";
import { PhoneMaskDirective } from "../../directives/phone-mask.directive";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-phone",
  templateUrl: "./phone.component.html",
})
export class PhoneComponent implements OnInit {
  @Input() viewProperties: PhoneViewProperties = new PhoneViewProperties();
  @Input() validationMessages: PhoneValidationMessages =
    new PhoneValidationMessages();
  @Output() onPhoneUpdated: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSSAConsentChecked: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  phoneNumber: string;
  oldValue: string;
  checkboxValue: boolean = true;

  // THIS CAN BE BINDED WITH AN ARRAY FROM DB IF USED,
  // REMEMBER TO ADD THE ATTRIBUTE [forbiddenNumbers]="forbiddenPhones" TO THE HTML...
  forbiddenPhones: string[] = environment.forbiddenNumbers;

  constructor() {}

  ngOnInit() {}

  @Input()
  set phoneValue(phoneValue: string) {
    try {
      if (phoneValue) {
        let phonePipe = new PhonePipe();
        phoneValue = phonePipe.transform(phoneValue, "");
        this.phoneNumber = phoneValue;
        this.oldValue = phoneValue;
      } else {
        this.oldValue = "";
      }
    } catch (ex) {
      throw ex;
    }
  }

  resetView() {
    try {
      if (this.oldValue) {
        this.phoneNumber = this.oldValue;
      } else {
        this.phoneNumber = "";
      }
    } catch (ex) {
      throw ex;
    }
  }

  updatePhone() {
    try {
      var phoneNumbersOnly = this.phoneNumber.match(/\d/g).join("");
      this.onPhoneUpdated.emit(phoneNumbersOnly);
    } catch (ex) {
      throw ex;
    }
  }

  onCheckboxChange() {
    try {
      let temp = this.checkboxValue;
      this.onSSAConsentChecked.emit(this.checkboxValue);
    } catch (ex) {
      throw ex;
    }
  }
}
