import { AddressValidationMessages } from './address-validation-messages';

export class AddressViewProperties {
    public UpdateButtonText: string;
    public CancelButtonText: string;
    public ButtonsVisible: boolean=true;
    public ReadOnly: boolean;
    public HeaderText: string;
    public AddressLine1LabelText: string;
    public AddressLine2LabelText: string;
    public CityLabelText: string;
    public StateLabelText: string;
    public ZipCodeLabelText: string;
    public Disabled: boolean;
    public TopDisclaimars: Array<string> = new Array<string>();
    public BottomDisclaimars: Array<string> = new Array<string>();
    public AddressLineMaxNumberOfCharactersAllowed: number;
    public AddressLine1CharacterCount: number;
    public AddressLine2CharacterCount: number;
    public CityCharacterCount: number;
    public CityMaxNumberOfCharacterAllowed: number
    public ValidationMessages: AddressValidationMessages;
    public ShowConsentInputSwitch: boolean;
    public ConsentLabelText: string;
    public ConsentYesLabelText: string;
    public ConsentNoLabelText: string;
    public ConsentValue: boolean;

}
