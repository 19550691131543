/*
** Routing module defines routing for the core module and all core views
*/

import { NgModule } from '@angular/core';
import { RouterModule, provideRoutes, Routes } from '@angular/router';

import { SiteMapComponent } from './site-map/site-map.component';
import { AuthorizeComponent } from './authorize/authorize.component';
import { LogoffComponent } from './logoff/logoff.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { WaitCursorComponent } from './wait-cursor/wait-cursor.component';
import { CommaSeparatedNumberPipe } from './shared/pipes/comma-separated-number.pipe';
import { TruncatePipe } from './shared/pipes/truncate.pipe';
import { SafePipe } from './shared/pipes/safe.pipe';
import { UppercaseInputDirective } from './shared/directives/uppercase-input.directive';
import { EmailComponent } from './shared/components/email/email.component';
import { PhoneComponent } from './shared/components/phone/phone.component';
import { AddressComponent } from './shared/components/address/address.component';
import { PCPInfoComponent } from './shared/components/pcp-info/pcp-info.component';
import { ProviderLinkComponent } from './shared/components/provider-link/provider-link.component';
import { PhoneMaskDirective } from './shared/directives/phone-mask.directive';
import { ZipCodeMaskDirective } from './shared/directives/zip-code-mask.directive';
import { ZipCodePipe } from './shared/pipes/zipcode.pipe';
import {ErrorSummaryComponent} from "./error-summary/error-summary.component";
import {MedImpactComponent} from './med-impact/med-impact.component'
// Constant array of routes.
const routes: Routes = [
    {
        path: 'sitemap',
        component: SiteMapComponent
    },
    {
        path: 'authorize',
        component: AuthorizeComponent
    },
    {
        path: 'logoff',
        component: LogoffComponent
    }
];

// Exported components used by main core module as declarations array
export const routedComponents = [
    SiteMapComponent,
    AuthorizeComponent,
    LogoffComponent,
    HeaderComponent,
    FooterComponent,
    WaitCursorComponent,
    CommaSeparatedNumberPipe,
    TruncatePipe,
    SafePipe,
    UppercaseInputDirective,
    EmailComponent,
    AddressComponent,
    PhoneComponent,
    ProviderLinkComponent,
    PCPInfoComponent,
    PhoneMaskDirective,
    ZipCodeMaskDirective,
    ZipCodePipe,
    ErrorSummaryComponent,
    MedImpactComponent
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class CoreRoutingModule { }
