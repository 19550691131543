import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';


import { MemberProfile } from '../../member-portal/shared/member-profile';
import { MemberProfileService } from '../../member-portal/shared/services/member-profile.service';
import { FooterSiteCoreTemplate } from '../../member-portal/shared/footer-sitecore-template';
import { FooterService } from '../../member-portal/shared/services/footer.service';
import { Configuration } from '../../app.constants';
import { MemberPortalFooter } from '../../member-portal/shared/member-portal-footer';
import {map, switchMap} from 'rxjs/operators';
import { INotifyChange, LanguageService } from '../../core/shared/services/language.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-contact-customer-service',
  providers: [FooterService],
  templateUrl: './contact-customer-service.component.html',
  styleUrls: ['./contact-customer-service.component.scss']
})
export class ContactCustomerServiceComponent implements OnInit, OnDestroy {
  @Input() getMemberProfile: boolean|string;

  private lineOfBusiness: string = this.config.EmptyString;
  private message: string;
  private customerServiceContacts: FooterSiteCoreTemplate;
  memberPortalFooter: MemberPortalFooter;
  public officeHours: string;
  private subscriptions = new SubSink();
  constructor(
    private memberProfileService: MemberProfileService,
    private footerService: FooterService,
    private config: Configuration,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    try {
      this.subscriptions.sink = this.languageService.notifyObservable$.subscribe((change: INotifyChange) => {
        if (change.propName === 'languageCode') {
          this.onLoad();
        }
      });
    }
    catch (ex) {
      throw ex;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onLoad() {


    this.subscriptions.sink = this.footerService.getCustomerServiceContactsSitecoreContent().subscribe(result=>{
        // sitecore content
        this.customerServiceContacts = result;
        if (this.getMemberProfile == false) {
          this.lineOfBusiness = this.config.MediCalLOBCode.toUpperCase();
          this.memberPortalFooter = this.getCustomerServiceText(
                                      this.lineOfBusiness,
                                      this.customerServiceContacts); 
        }
        else {
          if (this.getMemberProfile || this.getMemberProfile === undefined) {
            this.subscriptions.sink = this.memberProfileService.getMemberProfile().subscribe(response =>{
                let memberProfile = response;
                 this.setCustomerServiceText(memberProfile);
             });
          }
          else {
            this.setCustomerServiceText(this.getMemberProfile);
          }
        }
    });

  }


  private setCustomerServiceText(memberProfile){
            this.lineOfBusiness = (memberProfile.PersonalRepresentative === null ||
              (memberProfile.PersonalRepresentative.PersonalRepresentativeStatusId !== this.config.PersonalRepresentativePending ||
                memberProfile.PersonalRepresentative.PersonalRepresentativeStatusId !== this.config.PersonalRepresentativeDenied) ) 
              ? memberProfile.LOB.LOBCode : this.config.MediCalLOBCode.toUpperCase();

        this.memberPortalFooter = this.getCustomerServiceText(
        this.lineOfBusiness,
        this.customerServiceContacts
        );
  }

  private getCustomerServiceText(
    lineOfBusiness: string,
    contactInfo: FooterSiteCoreTemplate
  ): MemberPortalFooter {
    let result: MemberPortalFooter = new MemberPortalFooter();

    if (
      this.lineOfBusiness.toUpperCase() ===
      this.config.MediCalLOBCode.toUpperCase()
    ) {
      result.Address = contactInfo.MediCalAddress;
      result.CityStateZip = contactInfo.MediCalCityStateZip;
      result.DaysOfOperation = contactInfo.MediCalDaysOfOperation;
      result.HoursOfOperation = contactInfo.MediCalHoursOfOperation;
      result.PhoneNumber = contactInfo.MediCalPhoneNumber;
      result.BottomText = contactInfo.MediCalText;
      result.PhoneNumberMessage = contactInfo.MediCalPhoneNumberMessage;
      result.MainMessage = contactInfo.MainMessage;
      result.OfficeHours = contactInfo.MediCalOfficeHours;
    }
    else if (
      this.lineOfBusiness.toUpperCase() ===
      this.config.OneCareLOBCode.toUpperCase()
    ) {
      result.Address = contactInfo.OneCareAddress;
      result.CityStateZip = contactInfo.OneCareCityStateZip;
      result.DaysOfOperation = contactInfo.OneCareDaysOfOperation;
      result.HoursOfOperation = contactInfo.OneCareHoursOfOperation;
      result.PhoneNumber = contactInfo.OneCarePhoneNumber;
      result.BottomText = contactInfo.OneCareText;
      result.PhoneNumberMessage = contactInfo.OneCarePhoneNumberMessage;
      result.MainMessage = contactInfo.MainMessage;
      result.OfficeHours = contactInfo.OneCareOfficeHours;
    }
    else if (
      this.lineOfBusiness.toUpperCase() ===
      this.config.OneCareConnectLOBCode.toUpperCase()
    ) {
      result.Address = contactInfo.OneCareConnectAddress;
      result.CityStateZip = contactInfo.OneCareConnectCityStateZip;
      result.DaysOfOperation = contactInfo.OneCareConnectDaysOfOperation;
      result.HoursOfOperation = contactInfo.OneCareConnectHoursOfOperation;
      result.PhoneNumber = contactInfo.OneCareConnectPhoneNumber;
      result.BottomText = contactInfo.OneCareConnectText;
      result.PhoneNumberMessage = contactInfo.OneCareConnectPhoneNumberMessage;
      result.MainMessage = contactInfo.MainMessage;
      result.OfficeHours = contactInfo.OneCareConnectOfficeHours;

    }
    else if (
      this.lineOfBusiness.toUpperCase() === this.config.PaceLOBCode.toUpperCase()
    ) {
      result.Address = contactInfo.PaceAddress;
      result.CityStateZip = contactInfo.PaceCityStateZip;
      result.DaysOfOperation = contactInfo.PaceDaysOfOperation;
      result.HoursOfOperation = contactInfo.PaceHoursOfOperation;
      result.PhoneNumber = contactInfo.PacePhoneNumber;
      result.BottomText = contactInfo.PaceText;
      result.PhoneNumberMessage = contactInfo.PacePhoneNumberMessage;
      result.MainMessage = contactInfo.MainMessage;
      result.OfficeHours = contactInfo.PaceOfficeHours;
    }

    return result;
  }
}
