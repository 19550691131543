
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import {ErrorService} from './../shared/services/error.service'
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { catchError, finalize, map,retryWhen,timeout,delay ,take} from 'rxjs/operators';


@Injectable()
export class HTTPStatus {
    private requestInFlight$: BehaviorSubject<boolean>;

    constructor() {
        this.requestInFlight$ = new BehaviorSubject(false);
    }

    setHttpStatus(inFlight: boolean) {
        this.requestInFlight$.next(inFlight);
    }

    getHttpStatus(): Observable<boolean> {
        return this.requestInFlight$.asObservable();
    }
}

@Injectable()
export class HTTPListener implements HttpInterceptor {

    private numRequests = 0;
    constructor(private status: HTTPStatus, public errorService: ErrorService) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.status.setHttpStatus(true);
        this.numRequests++;
        if (req.method === 'POST') {
            return next
                .handle(req)
                .pipe(
                map(event => {
                    if (event instanceof HttpResponse) {
                        this.numRequests--;
                        if (this.numRequests <= 0) {
                            this.status.setHttpStatus(false);
                        }
                    }
                    return event;
                }),
                catchError(error => {
                    this.status.setHttpStatus(false);
                    this.numRequests = 0;
                    this.errorService.error = error.status;
                    return observableThrowError(error);
                })
                , timeout({first: 45000})); // add 45 second timeout for POST
        } else {
            return next
                .handle(req)
                .pipe(
                map(event => {

                    if (event instanceof HttpResponse) {
                        this.numRequests--;
                        if (this.numRequests <= 0) {
                            this.status.setHttpStatus(false);
                        }
                    }
                    return event;
                }),
                catchError(error => {
                    this.status.setHttpStatus(false);
                    this.numRequests = 0;
                    this.errorService.error = error.status;
                    return observableThrowError(error);
                })
                , retryWhen(error => {
                    console.log(error);
                    return error.pipe(delay(3000)
                        , take(10));
                })
                , timeout({first: 30000})); // add 30 second timeout
        }
    }
}
