import { Directive, Input } from "@angular/core";
import { NG_VALIDATORS, Validator, AbstractControl } from "@angular/forms";
import moment from 'moment';

@Directive({
    selector: '[appointmentDate]',
    providers: [{provide: NG_VALIDATORS, useExisting: AppointmentDateValidatorDirective, multi: true}]
  })
  export class AppointmentDateValidatorDirective implements Validator {
    @Input('appointmentDate') appointmentDate: string;
  
    validate(control: AbstractControl): {[key: string]: any} | null {
        
        var m = moment(this.appointmentDate);
        if(!m.isValid()){
            return {'InvalidDate': true};
        }
        
        let aptDate = new Date(this.appointmentDate);
        aptDate.setHours(0,0,0,0);
        let tomorrow = new Date();
        tomorrow.setHours(0,0,0,0);
        tomorrow.setDate( tomorrow.getDate() + 1);
        
        if(aptDate < tomorrow) {
            return {'NotFutureDate': true};
        }

        return null;
    }
  }