import { Injectable, Inject } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';



import { Configuration } from '../../../app.constants';
import { OAuthService } from 'angular-oauth2-oidc';
import { SearchForProviderSitecoreTemplate } from '../../shared/search-for-provider-sitecore-template';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import {publishReplay,refCount,catchError} from'rxjs/operators';
import { LanguageService } from './language.service';

@Injectable()
export class SearchForProviderService {

  constructor(private http: HttpClient, private configuration: Configuration, private languageService: LanguageService) {
  }

  getSitecoreContent(): Observable<SearchForProviderSitecoreTemplate> {
    try {
        return this.http.get<SearchForProviderSitecoreTemplate>(
          this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceSearchForProviderParameter)
          )
            .pipe(
            publishReplay(1)
            ,refCount()
            ,catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }
    catch (ex) {
      // log error
      return null;
    }
  }
}
