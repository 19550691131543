<div class="accordion">
    <div class="accordion-header">
        <h3>{{headerLabel}}</h3>
    </div>
    <div class="accordion-item" *ngFor="let item of items; let i = index">
        <button class="accordion-title" [class.active]="openSection === i" (click)="toggleSection(i)">
            {{item.Label}}
        </button>
        <div class="accordion-content" *ngIf="openSection === i">
            <p [innerHTML]="item.Description"></p>
        </div>
    </div>
</div>
