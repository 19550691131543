
<head>
    <title>mi_mp_sso</title>
    <META HTTP-EQUIV="CACHE-CONTROL" CONTENT="NO-CACHE">
</head>
<div *ngIf="memberProfile">
    <form ngNoForm  target="_blank" name="mi_mp_sso" id="mi_mp_sso" method="post" action="https://mp.medimpact.com/mp/sso/ExternalAccess.do">

        <input type="hidden" name="LoginType" value="external"/>
        <input type="hidden" name="PortalType" value="MP"/>
        <input type="hidden" name="productcode" value="MP"/>
        <input type="hidden" name="client_id" value="catadmin"/>
        <input type="hidden" name="client_key" value="87e57491790eb62b541521fa806f90f2"/>
        <input type="hidden" name="MemberNo" value={{memberProfile.CIN}}>
        <input type="hidden" name="DateOfBirth" value="{{memberProfile.DateOfBirth|date:'MM/dd/yyyy'}}"/>
        <input type="hidden" name="FirstName" value={{memberProfile.FirstName}}/>
        <input type="hidden" name="LastName" value={{memberProfile.LastName}}/>
        <input type="hidden" name="privateLabel" value="N"/>
        <input type="hidden" name="timeStamp" value="{{timeStamp}}"/>
        <input type="hidden" name="hash" value="{{hash}}">

    </form>
    </div>