export enum LanguageCode {
    en = 'en',
    esMX = 'es-MX',
    viVN = 'vi-VN',
    koKR = 'ko-KR',
    zhTW = 'zh-TW'
}

export enum TwoFactorStatus {
    START = 'START',
    PENDING_1 = 'PENDING_1',
    PENDING_2 = 'PENDING_2',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    ANSWER_ERROR = 'ANSWER_ERROR',
    PASSCODE_ERROR = 'PASSCODE_ERROR'
}
