import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'zipcode'
})

export class ZipCodePipe implements PipeTransform {
    transform(val: string, args): string { 
        //val = val.charAt(0) != 0 ? '0' + val : '' + val;
        if (val != undefined && val != null) {
            let newStr = val;
            let i = 0;

            if (val && val.length > 5 && val.indexOf('-') < 0) {
                newStr = val.substr(0, 5) + '-' + val.substr(5, 9)
            }
            else {
                newStr = val;
            }
            return newStr;
        }
        else {
            return '';
        }
    }
}