
export class SearchForProviderSitecoreTemplate {
    public OneCareConnectSearchLink: string;
    public OneCareSearchLink: string;
    public MediCalSearchLink: string;
    public OneCareDetailLink: string;
    public OneCareConnectDetailLink: string;
    public MediCalDetailLink: string;
    public RedirectToProviderSearchMessage: string;
    public OneCareConnectFacilityLink: string;
    public OneCareFacilityLink: string;
    public MediCalFacilityLink: string;
    public OneCareConnectBehaviorialHealthLink: string;
    public OneCareBehaviorialHealthLink: string;
    public MediCalBehaviorialHealthLink: string;
    public OneCareConnectPharmacyLink: string;
    public OneCarePharmacyLink: string;
    public MediCalPharmacyLink: string;
    public OneCareConnectVisionLink: string;
    public OneCareVisionLink: string;
    public MediCalVisionLink: string;
    public OneCareConnectDentalLink: string;
    public OneCareDentalLink: string;
    public MediCalDentalLink: string;
    public OneCareConnectUrgentLink: string;
    public OneCareUrgentLink: string;
    public MediCalUrgentLink: string;
  }
