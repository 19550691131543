import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Configuration } from '../../app.constants';
import { FooterService } from '../../member-portal/shared/services/footer.service';
import { SiteFooterTemplate } from '../shared/site-footer';
import { LanguageService } from '../shared/services/language.service';
import { MemberProfileService } from '../../member-portal/shared/services/member-profile.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  private subscriptions = new SubSink();
  public siteFooterTemplate: SiteFooterTemplate = new SiteFooterTemplate();
  public privacyPolicyPath: string;
  public noticeOfPrivacyPracticesPath: string;
  public currentYear: number;
  public errorMessage = "";
  public authenticated = false;

  constructor(public configuration: Configuration, 
    private languageService: LanguageService, 
    private memberProfileService: MemberProfileService, 
    private footerService: FooterService
    ) { }

  ngOnInit(): void {
    const date = new Date();
    this.privacyPolicyPath = this.configuration.MemberPrivacyPolicyUrl;
    this.noticeOfPrivacyPracticesPath = this.configuration.MemberNoticeOfPrivacyPracticesUrl;
    this.currentYear = date.getFullYear();

    this.subscriptions.sink = combineLatest([
      this.languageService.notifyObservable$,
      this.memberProfileService.notifyObservable$      
    ]).subscribe(result => this.loadView());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private loadView() {
    this.authenticated = (this.memberProfileService.memberProfile != null &&  this.memberProfileService.memberProfile != undefined);
    this.footerService.getSiteFooterTemplate().subscribe(
      response => {
        this.siteFooterTemplate = response;
        this.siteFooterTemplate.Copyright = this.siteFooterTemplate.Copyright.replace("{CURRENT_YEAR}", this.currentYear.toString());
      },
      error => {
        this.errorMessage = <any>error;
      }
    );
  }

}
