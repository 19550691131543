export class MemberLobProviderLinksModel {
    public ProviderSearchSitecoreLink: string;
    public ProviderDetailSitecoreLink: string;
    public ProviderFacilitySitecoreLink: string;
    public ProviderBehavioralHealthSitecoreLink: string;
    public ProviderPharmacySitecoreLink: string;
    public ProviderVisionSitecoreLink: string;
    public ProviderDentalSitecoreLink: string;
    public ProviderUrgentSitecoreLink: string;
}
