/*
** Component used to display HTML site-map for member portal
*/

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-site-map',
    templateUrl: './site-map.component.html',
    styleUrls: ['./site-map.component.scss']
})

export class SiteMapComponent {

    // Empty constructor
    constructor() { }

}
