<nav id="headerNavbar" class="navbar navbar-expand-xl bg-primary">
  <div class="container-fluid">
    
    <a id="logo-area" class="navbar-brand" href="#">
        <img src="assets/images/CO_Main_Logo_Color.png" alt="CalOptima Health">
    </a>

    <button id="btnNavbarOffcanvas" class="navbar-toggler" type="button" data-bs-toggle="collapse" 
      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" 
      aria-label="Toggle navigation">
      <i class="fa-duotone fa-bars fa-2xl fa-lob"></i>
    </button>

    <app-navigator *ngIf="!expired" class="collapse navbar-collapse" id="navbarSupportedContent" aria-labelledby="offcanvasNavbar2Label"></app-navigator>
    
  </div>
</nav>

<!-- <div class="masthead_area {{this.brandColor}}_bg">
  <div class="container-fluid">
    <div id="header-main-container">
      <div id="header-left-content">
        <div class="header-hello">
        <h3 class="{{this.brandColor}}" id="lblHeaderLoginGreeting">{{ headerContent.LoginGreetingLabel || 'hello' }}
          {{ firstName || 'member' }}!</h3>
      </div>
      </div>
        <div id="header-center-content" *ngIf="!disableForPersonalRepresentative">
          <div id="lblHeaderProgram" class="header-info"><strong>{{ headerContent.ProgramHeaderLabel || 'program:' }}
          </strong> {{ lineOfBusiness || 'lineOfBusiness' }}</div>
          <div id="lblHeaderEligibility" class="header-info">
            <strong>{{ headerContent.EligibilityHeaderLabel + ' ' || 'eligibility: ' }}</strong>{{ eligibility | truncate: 6 }}</div>
      </div>
      <div id="header-right-content" *ngIf="!disableForPersonalRepresentative">
          <div id="lblHeaderHealthNetwork" class="header-info">
            <strong>{{ headerContent.HealthNetworkHeaderLabel || 'health network:' }}</strong>
            {{ memberProfile?.HealthNetworkPlan?.HealthNetworkName | truncate: 22}}</div>
          <div id="lblHeaderPcpHeader" class="header-info"><strong>{{ headerContent.PcpHeaderLabel || 'your doctor:' }}
          </strong>
          <app-provider-link *ngIf="memberProfile" providerName="{{
                    memberProfile.PrimaryCareProviderInfo.ProviderName
                      | truncate: 22
                  }}" lineOfBusinessCode="{{ memberProfile.LOB.LOBCode }}" providerId="{{
                    memberProfile.PrimaryCareProviderInfo.ProviderId
                  }}" healthNetworkCode="{{
                    memberProfile?.HealthNetworkPlan?.HealthNetworkPlanCode
                  }}">
          </app-provider-link></div>
      </div>
    </div>
  </div>
</div> -->
<error-summary></error-summary>
