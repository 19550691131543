import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Configuration } from "../../../app.constants";
import { BehaviorSubject, Observable } from "rxjs";
import { LanguageCode } from "../../../member-portal/shared/enum";
import { LanguageMenuItem } from "../../../member-portal/shared/languageMenuItem";
import { publishReplay, refCount, catchError } from "rxjs/operators";
import { HttpResponseHelper } from "../helpers/http-response-helper";

export interface INotifyChange {
  propName: string;
  value: any;
}

@Injectable()
export class LanguageService {
  private notify = new BehaviorSubject<any>({
    propName: "languageCode",
    value: LanguageCode.en,
  });
  public notifyObservable$ = this.notify.asObservable();
  private _languageCode: string = LanguageCode.en;
  public get languageCode(): string {
    return this._languageCode;
  }
  public set languageCode(lang: string) {
    if (this._languageCode !== lang) {
      this._languageCode = lang;
      const data: INotifyChange = { propName: "languageCode", value: lang };
      this.notifyChange(data);
    }
  }

  constructor(private http: HttpClient, private configuration: Configuration) {}

  public notifyChange(data: any) {
    this.notify.next(data);
  }

  public getLanguageMenuItems(): Observable<Array<LanguageMenuItem>> {
    return this.http
      .get<Array<LanguageMenuItem>>(this.configuration.GetLanguageMenuUrl)
      .pipe(
        publishReplay(1),
        refCount(),
        catchError((error) =>
          HttpResponseHelper.handleError(this.configuration, error)
        )
      );
  }

  public getContentServiceUrlWithParameter(parameter: string) {
    let siteCoreContentServiceUrl =
      this.configuration.SitecoreContentServiceUrl.replace(/\/$/, "");
    let param = parameter.replace(/\/$/, "");
    return siteCoreContentServiceUrl + "/" + param + "/" + this.languageCode;
  }
}
