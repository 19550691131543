import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';


import { HeaderSitecoreTemplate } from '../header-sitecore-template';
import { Email } from '../Email';
import { Configuration } from '../../../app.constants';

import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import {catchError} from 'rxjs/operators';

@Injectable()
export class EmailService {
    private sendEmailServiceUrl;

    constructor(private http: HttpClient, private configuration: Configuration) {
        this.sendEmailServiceUrl = configuration.SendEmailServiceUrl
    }

    sendEmail(email: Email): Observable<string> {
        var errorResponse;
        return this.http.post<string>(this.sendEmailServiceUrl, email)
            .pipe(catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }

    sendEmailMailingAddress(): Observable<string> {
        var errorResponse;
        return this.http.post<string>(this.configuration.BaseMemberServiceUrl + "/sendemailupdatememberprofilemailingaddress", "")
            .pipe(catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }

    sendEmailPrimaryAddress(): Observable<string> {

        var errorResponse;
        return this.http.post<string>(this.configuration.BaseMemberServiceUrl + "/sendemailupdatememberprofileprimaryaddress", "")
            .pipe(catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }

    sendEmailPrimaryPhone(): Observable<string> {
        var errorResponse;
        return this.http.post<string>(this.configuration.BaseMemberServiceUrl + "/sendemailupdatememberprofileprimaryphonenumber", "")
            .pipe(catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }

    sendEmailMobilePhone(): Observable<string> {
        var errorResponse;
        return this.http.post<string>(this.configuration.BaseMemberServiceUrl + "/sendemailupdatememberprofilemobilephonenumber", "")
            .pipe(catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }

    sendEmailEmailAddress(email: Email): Observable<string> {
        var errorResponse;
        return this.http.post<string>(this.configuration.BaseMemberServiceUrl + "/sendemailupdatememberprofileemailaddress", email)
            .pipe(catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }

    sendEmailPreferredLanguage(): Observable<string> {
        var errorResponse;
        return this.http.post<string>(this.configuration.BaseMemberServiceUrl + "/sendemailupdatememberprofilepreferredlanguage", "")
            .pipe(catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
    }

}
