import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phone",
})
export class PhonePipe implements PipeTransform {
  transform(val: string, args): string {
    var nullstring = null;
    var empty = "";
    //val = val.charAt(0) != 0 ? '0' + val : '' + val;
    var newStr: string;
    val = val ? val.trim() : empty;
    if (val && val.length >= 10) {
      if (val.includes("-")) {
        newStr = val;
      } else {
        newStr =
          val.substr(0, 3) + "-" + val.substr(3, 3) + "-" + val.substr(6, 4);
      }
    } else {
      newStr = empty;
    }

    return newStr;
  }

  format(val: string, args): string {
    // Remove all non-digit characters from the input
    const cleaned = val.replace(/\D/g, "");
    // Check if cleaned phone number starts with "1"
    const hasLeadingOne = cleaned.charAt(0) === "1";
    // Extract country code ("1") if present or not
    const countryCode = hasLeadingOne ? "1-" : "1-";
    // Format phone number into groups of digits
    const formattedNumber = cleaned
      .substring(hasLeadingOne ? 1 : 0)
      .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    // Return the formatted phone number
    return countryCode + formattedNumber;
  }
}
