import { Injectable, Inject } from '@angular/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';


import { Configuration } from '../../../app.constants';
import { OAuthService } from 'angular-oauth2-oidc';

import { MemberProfile } from '../member-profile';
import { FooterSiteCoreTemplate } from '../footer-sitecore-template';
import { HttpResponseHelper } from '../../../core/shared/helpers/http-response-helper';
import {publishReplay, refCount, catchError} from 'rxjs/operators'
import { LanguageService } from '../../../core/shared/services/language.service';
import { FooterCardTemplate } from '../../../core/shared/footer-card-template';
import { SiteFooterTemplate } from '../../../core/shared/site-footer';


@Injectable()
export class FooterService {
  public footerCards: Array<FooterCardTemplate> = new Array<FooterCardTemplate>();
  constructor(private http: HttpClient, private configuration: Configuration, private languageService: LanguageService) {
  }

  getCustomerServiceContactsSitecoreContent(): Observable<FooterSiteCoreTemplate> {
    return this.http
      .get<FooterSiteCoreTemplate>(this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceFooterParameter))
        .pipe(
        publishReplay(1)
        , refCount()
        , catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
  }
  getFooterCardTemplate(cardType: string): Observable<FooterCardTemplate[]> {
    return this.http
      .get<FooterCardTemplate[]>(this.languageService.getContentServiceUrlWithParameter(this.configuration.SitecoreServiceFooterCardParameter + '.' + cardType))
        .pipe(
        publishReplay(1)
        , refCount()
        , catchError(error => HttpResponseHelper.handleError(this.configuration, error)));
  }

  updateFooterCardTemplate(cardType: string, isCaloptimaNetwork: boolean)
  {
     this.getFooterCardTemplate(cardType).subscribe(result=>{
      result.forEach(element => {
        if (!isCaloptimaNetwork) element.Body = this.removeHTML(element.Body, "interpretive_services");
      });
       
      this.footerCards = result;
    });
  }

  public getSiteFooterTemplate(): Observable<SiteFooterTemplate> {
    const url = this.languageService.getContentServiceUrlWithParameter(this.configuration.SiteFooterParameter);
    return this.http
      .get<SiteFooterTemplate>(url)
      .pipe(
        publishReplay(1)
        , refCount()
        , catchError(error =>
          HttpResponseHelper.handleError(this.configuration, error))
      );
  }

  removeHTML(innerHTML: string, remove: string){ 
    var tmp = document.createElement("DIV");
    tmp.innerHTML = innerHTML;
    var elements = tmp.getElementsByClassName(remove);
    for (let i = elements.length - 1; i >=0 ; i--) {
      elements[i].remove();      
    }
    return tmp.innerHTML;
  }
}
