export class PhoneViewProperties {
    public Title: string;
    public FieldLabel: string;
    public SubmitButtonText: string;
    public CancelButtonText: string;
    public ReadOnly: boolean;
    public CheckboxText: string = "";
    public ShowCheckbox: boolean = false;
    public ShowButtons: boolean = true;
    public UpdateMemberProfileText: string;
}