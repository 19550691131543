
<div class="modal fade" id="myModal" role="dialog">
     <div class="modal-dialog modal-md">
         <div class="modal-content">
             <div class="modal-header">
                 <h4 class="modal-title">{{sessionTimerSitecoreContent.SessionTimerHeader||'your session is about to expire'}}</h4>
                 <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>
             <div class="modal-body">
                 <p>{{sessionTimerSitecoreContent.SessionTimerTextBeforeTimer|| 'your session is about to time out in'}} {{timeLeftForSessionToExpireInput}}.{{sessionTimerSitecoreContent.SessionTimerTextAfterTimer||'You will be asked to log in to Member Portal again after this time.'}}</p>
             </div>
             <div class="modal-footer">
                 <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="unsubscibeFromTimer()">{{sessionTimerSitecoreContent.SessionTimerOkButtonText||'ok'}}
                 </button>
             </div>
         </div>
    </div>
</div>
