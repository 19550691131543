import { Injectable, OnInit } from '@angular/core';

import { QueryStringParam } from '../query-string-param';
import { Configuration } from '../../../app.constants';

@Injectable()
export class RoutingHelper{

    constructor(private configuration: Configuration) {}

    getUrlWithQueryString(url: string, queryParams: QueryStringParam[]): string {
        let queryString = [];
        for (let p of queryParams){
            queryString.push(encodeURIComponent(p.keyName) + this.configuration.EqualOperator + encodeURIComponent(String(p.keyValue)));
        }
        return url + this.configuration.UrlParameterStartTag + queryString.join(this.configuration.UrlParameterAndTag);
    }

}