import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { MemberLobProviderLinksModel } from '../member-lob-provider-sitecore-template';
import { Configuration } from '../../../app.constants';
import { RoutingHelper } from '../../../core/shared/helpers/routing-helper';
import { SearchForProviderService } from '../../../core/shared/services/search-for-provider.service';
import { SearchForProviderSitecoreTemplate } from '../search-for-provider-sitecore-template';
import {map} from 'rxjs/operators';

@Injectable()

export class ProviderLinkService {
    private searchForProviderContent: SearchForProviderSitecoreTemplate = new SearchForProviderSitecoreTemplate();

    constructor(
        private searchForProviderService: SearchForProviderService,
        private configuration: Configuration,
        private routingHelper: RoutingHelper
    ) { }

    public getProviderSearchTemplate(): Observable<SearchForProviderSitecoreTemplate> {
        return this.searchForProviderService.getSitecoreContent()
            .pipe(
             map(response => {
                this.searchForProviderContent = response;
                return response;
            },
            error => {
                throw error;
            }));
    }

    public getMemberLobProviderLinks(lineOfBusiness: string): Observable<MemberLobProviderLinksModel> {
        return this.searchForProviderService.getSitecoreContent()
            .pipe(
                map(
                response => {
                    this.searchForProviderContent = response;
                    return this.getLobProviderLinks(this.searchForProviderContent, lineOfBusiness);
                },
                error => {
                    throw error;
                }
            ));
    }

    public getLobProviderLinks(
        providerContent: SearchForProviderSitecoreTemplate,
        lineOfBusiness: string): MemberLobProviderLinksModel {
        let lobSearchSubLink: string;
        let lobDetailSublink: string;
        let lobFacilitySubLink: string;
        let lobBehavorialHealthSubLink: string;
        let lobPharmacySubLink: string;
        let lobVisionSubLink: string;
        let lobDentalSubLink: string;
        let lobUrgentSubLink: string;

        const memberLobProviderLinksModel = new MemberLobProviderLinksModel();

        switch (lineOfBusiness.toUpperCase()) {
            case this.configuration.MediCalLOBCode.toUpperCase():
                lobSearchSubLink = providerContent.MediCalSearchLink;
                lobDetailSublink = providerContent.MediCalDetailLink;
                lobFacilitySubLink = providerContent.MediCalFacilityLink;
                lobBehavorialHealthSubLink = providerContent.MediCalBehaviorialHealthLink;
                lobPharmacySubLink = providerContent.MediCalPharmacyLink;
                lobVisionSubLink = providerContent.MediCalVisionLink;
                lobDentalSubLink = providerContent.MediCalDentalLink;
                lobUrgentSubLink = providerContent.MediCalUrgentLink;
                break;
            case this.configuration.OneCareConnectLOBCode.toUpperCase():
                lobSearchSubLink = providerContent.OneCareConnectSearchLink;
                lobDetailSublink = providerContent.OneCareConnectDetailLink;
                lobFacilitySubLink = providerContent.OneCareConnectFacilityLink;
                lobBehavorialHealthSubLink = providerContent.OneCareConnectBehaviorialHealthLink;
                lobPharmacySubLink = providerContent.OneCareConnectPharmacyLink;
                lobVisionSubLink = providerContent.OneCareConnectVisionLink;
                lobDentalSubLink = providerContent.OneCareConnectDentalLink;
                lobUrgentSubLink = providerContent.OneCareConnectUrgentLink;
                break;
            case this.configuration.OneCareLOBCode.toUpperCase():
                lobSearchSubLink = providerContent.OneCareSearchLink;
                lobDetailSublink = providerContent.OneCareDetailLink;
                lobFacilitySubLink = providerContent.OneCareFacilityLink;
                lobBehavorialHealthSubLink = providerContent.OneCareBehaviorialHealthLink;
                lobPharmacySubLink = providerContent.OneCarePharmacyLink;
                lobVisionSubLink = providerContent.OneCareVisionLink;
                lobDentalSubLink = providerContent.OneCareDentalLink;
                lobUrgentSubLink = providerContent.OneCareUrgentLink;
                break;
            default:
                lobSearchSubLink = providerContent.MediCalSearchLink;
                lobDetailSublink = providerContent.MediCalDetailLink;
                lobFacilitySubLink = providerContent.MediCalFacilityLink;
                lobBehavorialHealthSubLink = providerContent.MediCalBehaviorialHealthLink;
                lobPharmacySubLink = providerContent.MediCalPharmacyLink;
                lobVisionSubLink = providerContent.MediCalVisionLink;
                lobDentalSubLink = providerContent.MediCalDentalLink;
                lobUrgentSubLink = providerContent.MediCalUrgentLink;
        }

        memberLobProviderLinksModel.ProviderSearchSitecoreLink =
            this.configuration.BaseSitecoreUrl + lobSearchSubLink;
        memberLobProviderLinksModel.ProviderDetailSitecoreLink =
            this.configuration.BaseSitecoreUrl + lobDetailSublink;
        memberLobProviderLinksModel.ProviderFacilitySitecoreLink =
            this.configuration.BaseSitecoreUrl + lobFacilitySubLink;
        memberLobProviderLinksModel.ProviderBehavioralHealthSitecoreLink =
            this.configuration.BaseSitecoreUrl + lobBehavorialHealthSubLink;
        memberLobProviderLinksModel.ProviderPharmacySitecoreLink = lobPharmacySubLink.startsWith("http") ? lobPharmacySubLink :
            this.configuration.BaseSitecoreUrl + lobPharmacySubLink;
        memberLobProviderLinksModel.ProviderVisionSitecoreLink =
            this.configuration.BaseSitecoreUrl + lobVisionSubLink;
        memberLobProviderLinksModel.ProviderDentalSitecoreLink =
            this.configuration.BaseSitecoreUrl + lobDentalSubLink;
            memberLobProviderLinksModel.ProviderUrgentSitecoreLink =
            this.configuration.BaseSitecoreUrl + lobUrgentSubLink;
        return memberLobProviderLinksModel;
    }

    getProviderDetailsUrl(providerSearchUrl: string, providerId: string, hashedDate: string): string {
        const queryParams = [
            {
                keyName: this.configuration.ProviderIdQueryParamName,
                keyValue: providerId
            },
            { keyName: this.configuration.HashedDateParamName, keyValue: hashedDate }
        ];

        return this.routingHelper.getUrlWithQueryString(
            providerSearchUrl,
            queryParams
        );
    }
}
