export class Log {

    public Date: Date
    public Thread: string
    public Level: string
    public Component: string
    public Message: string
    public Exception: string
    public HostName: string
    public UserName: string
}