import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'commaSeparated'
})

export class CommaSeparatedNumberPipe implements PipeTransform {

  transform(value: number, args): string {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}
