import { Directive, EventEmitter, HostListener, Output} from '@angular/core';
import { NgControl } from "@angular/forms";
@Directive({
    selector: '[ngModel][zipcode]'

})
export class ZipCodeMaskDirective {
    constructor(public model: NgControl) { }

    @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
    value: any;

    @HostListener('input', ['$event']) onInputChange($event, backspace) {
        // remove all mask characters (keep only numeric)
        if ($event.target.value) {
            this.value = $event.target.value.replace(/\D/g, '');
            // special handling of backspace necessary otherwise
            // deleting of non-numeric characters is not recognized
            // this laves room for improvement for example if you delete in the 
            // middle of the string
            if (backspace) {
                this.value = this.value.replace(0, this.value.length - 1);
            }

            // don't show braces for empty value
            if (this.value.length == 0) {
                this.value = '';
            }
            // don't show braces for empty groups at the end

            if (this.value.length > 5) {
                this.value = this.value.replace(/^(\d{0,5})(\d{0,4})/, '$1-$2');

            }
        }
        this.ngModelChange.emit(this.value);
        // set the new value
        //this.model.valueAccessor.writeValue(newVal);

    }
}