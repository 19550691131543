/// <reference path="../../member-portal/shared/member-profile.ts" />
import { Component, OnInit, Input, Output, SimpleChanges, SimpleChange } from '@angular/core';
import { AuthorizationsService } from '../../member-portal/shared/services/authorizations.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MemberProfileService } from '../../member-portal/shared/services/member-profile.service';
import { MemberProfile } from '../../member-portal/shared/member-profile'
import {DatePipe} from '@angular/common';
import {Md5} from 'ts-md5/dist/md5';
import moment, { Moment } from 'moment';
import { Configuration } from '../../app.constants';



@Component({
    selector: 'med-impact',
    templateUrl: './med-impact.component.html'

})
export class MedImpactComponent implements OnInit  {

    memberProfile: MemberProfile;
    timeStamp;
    hash;
    
    constructor(public configuration: Configuration) { }

    ngOnInit() { }

    ngAfterViewInit(){

        //submit after init.
        document.forms["mi_mp_sso"].submit();
    }

    SetValues(memberProfile: MemberProfile){
        
        this.memberProfile =memberProfile; 
        this.timeStamp = moment.utc().format(this.configuration.MedImpactTimeFormat);
        this.hash = Md5.hashStr(this.configuration.MedImpactHashKey + this.timeStamp + this.memberProfile.CIN);
    }

}
