import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EmailViewProperties } from '../../email-view-properties';
import { EmailValidationMessages } from '../../email-validation-messages';

@Component({
    selector: 'app-email',
    templateUrl: './email.component.html'
})
export class EmailComponent implements OnInit {
    @Input() viewProperties: EmailViewProperties = new EmailViewProperties();
    @Input() validationMessages: EmailValidationMessages = new EmailValidationMessages();

    @Output() onEmailUpdated: EventEmitter<string> = new EventEmitter<string>();

    emailText: string;
    oldValue: string;

   constructor() {
    }

    ngOnInit() { }
    

    @Input()
    set emailValue(emailValue: string) {
        this.emailText = emailValue;
        this.oldValue = emailValue;
    }

    resetView() {
        this.emailText = this.oldValue;
    }

    updateEmail() {
        this.onEmailUpdated.emit(this.emailText);
    }

}

