import { Component, OnChanges, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { BlockUI } from 'primeng/blockui';
import {ProgressSpinner} from 'primeng/progressspinner';

@Component ({
    selector: 'wait-cursor',
    templateUrl: './wait-cursor.component.html',
    styleUrls: ['./wait-cursor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class WaitCursorComponent {
    @Input() show: boolean = false;
}
