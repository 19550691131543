import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { SubSink } from 'subsink';
import { Configuration } from '../../app.constants';
import { FooterService } from '../../member-portal/shared/services/footer.service';
import { MemberProfileService } from '../../member-portal/shared/services/member-profile.service';
import { INotifyChange, LanguageService } from '../shared/services/language.service';

@Component({
  selector: 'app-footer-card',
  templateUrl: './footer-card.component.html',
  styleUrls: ['./footer-card.component.scss']
})
export class FooterCardComponent implements OnInit, OnDestroy {

  private subscriptions = new SubSink();

  constructor(
    private config: Configuration,
    public footerService: FooterService, 
    private languageService: LanguageService, 
    private memberProfileService: MemberProfileService) { }

  ngOnInit(): void {
    this.subscriptions.sink = combineLatest([
      this.languageService.notifyObservable$,
      this.memberProfileService.notifyObservable$
    ]).subscribe(result => this.loadFooter());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadFooter()
  {
    let memberProfile = this.memberProfileService.memberProfile;
    if(!memberProfile)
    {
      this.footerService.updateFooterCardTemplate(Configuration.CardType.UnAuthenticated, false);
    }
    else{
      let cardFooterType = Configuration.CardType.UnAuthenticated;
      const activePR = memberProfile.IsEligible
          && memberProfile.PersonalRepresentative
          && memberProfile.PersonalRepresentative.PersonalRepresentativeStatusId !== this.config.PersonalRepresentativePending
          && memberProfile.PersonalRepresentative.PersonalRepresentativeStatusId !== this.config.PersonalRepresentativeDenied
          
      const activeNormalMember = memberProfile.IsEligible && !memberProfile.PersonalRepresentative ;

      if(memberProfile.LOB.LOBCode)
      {
        if(activeNormalMember || activePR){
          if(memberProfile.LOB.LOBCode == this.config.PaceLOBCode) cardFooterType = Configuration.CardType.Pace;
          else if(memberProfile.LOB.LOBCode == this.config.MediCalLOBCode) cardFooterType = Configuration.CardType.MediCal;
          else if(memberProfile.LOB.LOBCode == this.config.OneCareLOBCode) cardFooterType = Configuration.CardType.OneCare;
          else if(memberProfile.LOB.LOBCode == this.config.OneCareConnectLOBCode) cardFooterType = Configuration.CardType.OCC;
        }
        else{
          if(memberProfile.LOB.LOBCode == this.config.PaceLOBCode) cardFooterType = Configuration.CardType.Pace;
          else if(memberProfile.LOB.LOBCode == this.config.MediCalLOBCode) cardFooterType = Configuration.CardType.ViewOnly_MediCal;
          else if(memberProfile.LOB.LOBCode == this.config.OneCareLOBCode) cardFooterType = Configuration.CardType.ViewOnly_OneCare;
          else if(memberProfile.LOB.LOBCode == this.config.OneCareConnectLOBCode) cardFooterType = Configuration.CardType.ViewOnly_OCC;
        }
        
        const  isCaloptimaNetwork = (memberProfile.HealthNetworkClassCode == 'CCN' || memberProfile.HealthNetworkClassCode == 'COD');
        this.footerService.updateFooterCardTemplate(cardFooterType, isCaloptimaNetwork);
      }
    }
  }
}
