import {Injectable} from '@angular/core';

@Injectable()

export class ErrorService {
    errorMessage :any;
    private _error: any;
    set error(value: any) {
        this._error = value;
        switch (value) {
            case 500:
                this.errorMessage = "We're sorry. An error has occurred. Please <a href='' class='a alert-danger' (click)='location.reload()'>refresh the page</a>. If you continue to encounter this error, please contact Customer Service."
                break;
            case 401:
                this.errorMessage = "We're sorry. You are unauthorized to access this service. Please contact customer service.";
                break;
            case 503:
                this.errorMessage = "We're sorry. The service is not available. Please contact customer service";
                break;
            case 400 :
                this.errorMessage = "We're sorry. An error has occurred. Please <a href=''class='a alert-danger' (click)='location.reload()'>refresh the page</a>. If you continue to encounter this error, please contact Customer Service."
                break;
            case 0:
               this.errorMessage = "We're sorry. An error has occurred. Please <a href='' class='a alert-danger' (click)='location.reload()'>refresh the page</a>. If you continue to encounter this error, please contact Customer Service."
                break;
        }

    }
    get error(): any {

        return this._error;

    }

}