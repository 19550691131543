import { Injectable } from '@angular/core';

import { OAuthService } from 'angular-oauth2-oidc';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthHelper {

    constructor(private authService: OAuthService, public jwtHelper: JwtHelperService) {

    }

    public tokenNotExpired(): boolean {
        let token = this.authService.getAccessToken();
        if (token == null) {
            return false;
        }
        else {
            return !this.jwtHelper.isTokenExpired(token);
        }
    }

   
}