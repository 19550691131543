export class PhoneProperties {

    public Value: string = '';
    public Placeholder: string;
    public LabelText: string;
    public Name: string;
    public IsValid: boolean;
    public TabIndex: string;
    public ErrorMessage: string;
    public RequiredMessage: string;
    public IsRequired: boolean;
}
