
import { ErrorHandler, Injectable, Injector, } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LoggingService } from './services/logging.service'
import { ActivatedRoute } from '@angular/router'

@Injectable()
export class ErrorsHandler extends ErrorHandler {

    constructor(private injector: Injector) { super(); }

    public handleError(error: Error) {
            const loggingService = this.injector.get(LoggingService);
            loggingService.logError(error);
        }
    
}
