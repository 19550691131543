<div *ngIf="show" class="progress-spinner-style">
 
    <p-progressSpinner
      [style]="{ width: '125px', height: '125px' }"
      strokeWidth="3"
      fill="#F2F1F1"
      animationDuration="2s"
    ></p-progressSpinner>
 
</div>
